.p-breadcrumb {
  @include pc{
    width: #{$contents-width}px;
    margin: 0 auto;
  }
  @include sp{
    width: 100%;
  }
  &__list{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;
    height: 50px;
    border: 1px solid;
    padding: 10px;
    @include pc{
    }
    @include sp{
    }
    li {

    }
    a{

    }
  }
}