.p-serials {
  border-radius: 24px;
  @include pc{
    width: 760px;
    margin: 40px auto;
    padding: 40px 80px 46px;
  }
  @include sp{
    width: calc(100% - 40px);
    margin: 40px auto;
    padding: 6%;
  }
  [data-view-mode="light"] & {
    background-color: $bgColor-mode-light;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);

  }
  [data-view-mode="dark"] & {
    background-color: rgb(26, 26, 26);
    box-shadow: 0px 8px 56px 0px rgba(6, 0, 1, 0.9);
  }

  &_input {
    dt {
      font-size: 22px;
      font-weight: bold;
      text-align: center;
      &:first-child
      & ~ dt,
      & ~ dd {
        margin-top: 20px;
        margin-bottom: 20px;
      }
    }
    dd {
      & ~ dt,
      & ~ dd {
        margin-top: 10px;
      }
    }
    input {
      appearance: none;
      border: none;
      outline: none;
      border-radius: 10px;
      width: 100%;
      min-height: 72px;
      padding: 20px 1em;
      [data-view-mode="light"] & {
        color: $bgColor-mode-dark;
        border: 1px solid $bgColor-mode-dark;
        background-color: $bgColor-mode-light;
      }
      [data-view-mode="dark"] & {
        color: $bgColor-mode-light;
        border: 1px solid $bgColor-mode-dark;
        background-color: $input-color2;
      }
    }
  }

  &_button {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    @include pc{
      margin-top: 72px;
    }
    @include sp{
      margin-top: 72px;
    }
    &_item {
      display: inline-flex;
      gap: 20px;

      @include pc{
        width: 600px;
        min-height: 72px;
      }
      @include sp{
        width: 100%;
        min-height: 72px;
      }
      input[type="submit"] {
        // background-repeat: no-repeat;
        // background-position: center right 30px;
        // background-size: 26px 26px;
      }
      button, input[type="submit"] {
        appearance: none;
        border: none;
        outline: none;
        cursor: pointer;
      }
      a, button, input[type="submit"] {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        text-decoration: none;
        border-width: 1px;
        border-style: solid;
        border-radius: 36px;
        transition: .2s;
        position: relative;
        @include pc{
          font-size: 20px;
          padding: 1em 65px;
        }
        @include sp{
          font-size: 20px;
          padding: 1em 13%;
        }
        &:hover {
          opacity: 1;
        }
      }

      &.r-application {
        input[type="submit"] {
          background-repeat: no-repeat;
          background-size: 26px 26px;
          @include pc{
            background-position: center right 30px;
          }
          @include sp{
            background-position: center right 7%;
          }
        }
        a, button{
          &::after {
            content: "";
            display: inline-block;
            position: absolute;
            top: 50%;
            right: 30px;
            transform: translate(0%, -50%);
            background: no-repeat center center / 100% auto;

            @include pc{
              width: 26px;
              height: 26px;
            }
            @include sp{
              width: 26px;
              height: 26px;
            }
          }
        }
      }

      // ダークモード
      [data-view-mode="light"] & {
        // アイコン切り替え
        &.r-application {
          input[type="submit"] {
            background-image: url(../images/icon_button_on.svg);
            &:hover{
              background-image: url(../images/icon_button_off.svg);
            }
          }
          [data-mode-target=button] {
            &::after {
              background-image: url(../images/icon_button_on.svg);
            }
            &:hover::after {
              background-image: url(../images/icon_button_off.svg);
            }
          }
        }
      }
      [data-view-mode="dark"] & {
        // アイコン切り替え
        &.r-application {
          input[type="submit"] {
            background-image: url(../images/icon_button_off.svg);
            &:hover{
              background-image: url(../images/icon_button_on.svg);
            }
          }
          [data-mode-target="button"] {
            &::after {
              background-image: url(../images/icon_button_off.svg);
            }
            &:hover::after {
              background-image: url(../images/icon_button_on.svg);
            }
          }
        }

      }


    }
  }
}
