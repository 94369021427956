.u-color{
  &--blue{
    color: $color-font4 !important;
  }
  // &--attention{
  //   color: $color-attention !important;
  // }
  &--error{
    color: $color-error !important;
  }
}
