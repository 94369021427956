.l-main{
  flex: 1 1 auto;
  // background-color: var(--content-bg-color-1);
  width: 100%;
  color: $color-font1;
  // border: 1px solid var(--main-text-color);
  @include pc{
    // margin: 0 auto;
    // padding: 40px;
  }
  @include sp{
    // padding: 20px;
  }
} 