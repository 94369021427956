.c-select {
  margin: 0;
  padding: 0;
  position: relative;
  border: 1px solid;
  border-radius: 10px;
  [data-view-mode="light"] & {
    border-color: $button-color1;
  }
  [data-view-mode="dark"] & {
    border-color: #fff;
  }
  &::after {
    pointer-events: none;
    content: "";
    width: 10px;
    height: 10px;
    border-width: 0 2px 2px 0;
    border-style: solid;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
    [data-view-mode="light"] & {
      border-color: $button-color1;
    }
    [data-view-mode="dark"] & {
      border-color: #fff;
    }

  }
  select {
    background: none;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    border-radius: 10px;
    padding: 9px calc(1em + 15px) 9px 1em;
    appearance: none;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    cursor: pointer;
    border: none !important;
    width: 100%;
    @include pc{
      max-width: 210px;
    }
    @include sp{
      max-width: 100%;
    }
    &::-ms-expand {
      display: none;
    }

    &.r-birthyear {
      min-width: 10em;
    }
    &.r-birthmonth {
      min-width: 6em;
    }
    &.r-birthday {
      min-width: 6em;
    }
    &.r-country {
      min-width: 15em;
    }
    option {

    }
  }
}