.p-sitelogo{
  display: flex;
  align-items: center;
  max-width: 100%;
  @include pc{
    height: 80px;
    padding: 0 22px;
  }
  @include sp{
    height: 57px;
    padding: 0 10px;
  }
  a {
    display: inline-flex;
    height: 100%;
  }
  img {
    max-width: 100%;
    max-height: 100%;
    vertical-align: middle;
  }
}