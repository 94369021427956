.p-footer{
  align-items: center;
  text-align: center;
  padding: 1em;
  @include pc{
    width: #{$contents-width}px;
    margin-right: auto;
    margin-left: auto;
  }
  @include sp{
    
  }
  small {
    
  }
  a {
    color: currentColor;
    text-decoration: none;
    font-size: 0.9em;
  }
}