.p-live {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 0 80px;
  width: 100%; 
  @include sp {
    display: block;
  }
}

.p-livePlayer {
  width: 55%;
  @include sp {
    width: 100%;
  }
}

.p-playerVideo{
  height: 0px;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
}

.p-liveInfo {
  width: calc(45% - 64px);
  @include sp {
    margin-top: 40px;
    width: 100%;
  }
}

.p-liveInfoBox { 
  border-radius: 15px;
  box-sizing: border-box;
  padding: 38px 32px;
  width: 100%;
  box-shadow: 0px 2px 10px rgba(0,0, 0, .2);
  @include sp {
    padding: 30px 16px;
  }
}

.p-liveInfoBoxTtl {
  background-size: 30px 20px;
  background-position: left center;
  background-repeat: no-repeat;
  font-size: 1.8rem;
  font-weight: bold;
}

[data-view-mode="light"] [data-mode-target="color"] .p-liveInfoBox {
  background-color: #fff;
}

[data-view-mode="dark"] [data-mode-target="color"] .p-liveInfoBox {
  background-color: #1a1a1a;
  color: #fff;
}