// フォント
$fontFamily: YuGothic, "Yu Gothic Medium", "Yu Gothic", Verdana, Meiryo, sans-serif;

//サイズ
// モバイルフレンドリー
$mobileSize: 320;

// viewport
$breakpoint: 768px;
$breakpoints: (
  sm: (max-width: 567px),
  md: (max-width: 768px),
  lg: (max-width: 992px),
  xl: (max-width: 1280px),
  xxl: (min-width: 768px + 1px)
) !default;

// contents width
$contents-width: 1200 !default;

// color
$color-main: #333;

$color-font1: $color-main;
$color-font2: #fff;
$color-font3: #bebebe;
$color-font4: #00b4ed;

$color-link: #00b4ed;
$color-attention: red;
$color-error: red;

$button-color1: #333;
$button-color2: #fff;

$input-color1: #f5f5f5;
$input-color2: #252525;

$border-color1: #dcdcdc;

// 
$pagetitle-bg-color-1: #fff200;
$content-bg-color-1: #fffec1;

// ダークモード
$bgColor-mode-light: #fff;
$bgColor-mode-dark: #000;

// button size
$button-size1: 666;
$button-size2: 414;
$button-size3: 160;
$button-height1: 56;
$button-height2: 48;

// フォーム
$input-size: 480;
$input-height: 56;
$input-color: 480;

// fontsize
$fontsize-xl: 20;
$fontsize-l: 18;
$fontsize-m: 16;
$fontsize-s: 14;
$fontsize-xs: 13;

// lineheight
$lineheight-xl: 2.4;
$lineheight-l: 2;
$lineheight-m: 1.7;
$lineheight-s: 1.2;
$lineheight-xs: 1;


// ヘッダー
$header-height: 57;
$header-bg-color: #fff;

// フッター
$footer-color: #fff;
$footer-bg: #1e1e1e;
$footer-height: 56;

// コピーライト
$copy-color: #fff;
$copy-size: 11;

