.is-errorTitle{
  font-size: #{$fontsize-m}px;
  color: $color-error !important;
  margin-bottom: 32px;
  padding: 16px;
  box-shadow: 0 0 0 1px $color-error inset;
}
.is-attentionTitle{
  font-size: #{$fontsize-m}px;
  color: $color-main !important;
  margin-bottom: 32px;
  padding: 16px;
  box-shadow: 0 0 0 1px $color-main inset;
}
.is-noteTitle{
  font-size: #{$fontsize-m}px;
  color: $color-font1 !important;
  margin-bottom: 32px;
  padding: 16px;
  box-shadow: 0 0 0 1px $color-font1 inset;
}
.is-inputError{
  box-shadow: 0 0 0 1px $color-error inset !important;
}
.is-error{
  font-size: #{$fontsize-s}px !important;
  color: $color-error !important;
}
.is-note{
  font-size: #{$fontsize-s}px !important;
  color: $color-font1 !important;
}

