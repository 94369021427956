@charset "UTF-8";
@font-face {
  font-family: "NotoSans";
  src: url("../font/NotoSansJP-Medium.woff2") format("woff2"), url("../font/NotoSansJP-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "NotoSans";
  src: url("../font/NotoSansJP-Regular.woff2") format("woff2"), url("../font/NotoSansJP-Regular.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

/*-----------------------------------------------
	Browser Reset
-----------------------------------------------*/
body {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6, pre, address, em, strong, th, ins {
  font-size: 100%;
  font-weight: normal;
}

h1, h2, h3, h4, h5, h6, pre, address, ul, ol, li, dl, dt, dd, form, legend, p {
  margin: 0;
  padding: 0;
}

table, tr, td, th {
  margin: 0;
  padding: 0;
  border-collapse: collapse;
  border: 0;
}

a, button {
  word-break: break-all;
}

img {
  border: none;
}

ul:not([class]), ol:not([class]) {
  text-indent: -1em;
  padding-left: 1em;
}

ul:not([class]) li, ol:not([class]) li {
  text-indent: 0;
}

header, section, footer, aside, nav, article, figure, main {
  margin: 0;
  padding: 0;
  display: block;
}

body, th, td {
  word-wrap: break-word;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: inherit;
  background: none;
  vertical-align: bottom;
}

input[type=radio] {
  margin: 0;
  vertical-align: middle;
}

button {
  appearance: none;
  border: none;
  outline: none;
}

button:focus {
  outline: inherit;
}

video {
  max-width: 100%;
  vertical-align: bottom;
}

::placeholder {
  font-family: Arial;
}

/*------------------------------------------------------------
共通
------------------------------------------------------------*/
html {
  font-size: 62.5%;
}

@media (min-width: 769px) {
  html {
    min-width: 1200px;
  }
}

@media (max-width: 768px) {
  html {
    min-width: 100%;
  }
}

body {
  display: flex;
  flex-direction: column;
  -webkit-text-size-adjust: 100%;
  font-family: NotoSans, sans-serif;
  min-height: 100vh;
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 1.6;
  color: #333;
}

*, *::after, *::before {
  box-sizing: border-box;
}

img {
  max-width: 100%;
  vertical-align: bottom;
}

input[type="submit"], button {
  cursor: pointer;
}

a, button {
  transition: opacity .2s;
}

a:hover, button:hover {
  opacity: .6;
}

button {
  cursor: pointer;
}

select::-ms-expand {
  display: none;
}

em {
  font-style: normal;
}

.l-header {
  color: #fff;
}

@media (min-width: 769px) {
  .l-header {
    width: 100%;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .l-header {
    width: 100%;
  }
}

.l-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  min-height: 100vh;
  margin: 0 auto;
}

.l-footer {
  width: 100%;
}

@media (min-width: 769px) {
  .l-footer {
    padding-top: 20px;
  }
}

@media (max-width: 768px) {
  .l-footer {
    padding-top: 20px;
  }
}

.l-main {
  flex: 1 1 auto;
  width: 100%;
  color: #333;
}

/*
** コンポーネント
*/
@media (min-width: 769px) {
  .c-pageText {
    font-size: 16px;
  }
}

@media (max-width: 768px) {
  .c-pageText {
    font-size: 16px;
  }
}

.c-inputError {
  display: block;
  color: red;
  text-align: center;
}

.c-formError {
  display: block;
  color: red;
}

.c-select {
  margin: 0;
  padding: 0;
  position: relative;
  border: 1px solid;
  border-radius: 10px;
}

[data-view-mode="light"] .c-select {
  border-color: #333;
}

[data-view-mode="dark"] .c-select {
  border-color: #fff;
}

.c-select::after {
  pointer-events: none;
  content: "";
  width: 10px;
  height: 10px;
  border-width: 0 2px 2px 0;
  border-style: solid;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%) rotate(45deg);
}

[data-view-mode="light"] .c-select::after {
  border-color: #333;
}

[data-view-mode="dark"] .c-select::after {
  border-color: #fff;
}

.c-select select {
  background: none;
  border: none;
  outline: none;
  margin: 0;
  padding: 0;
  border-radius: 10px;
  padding: 9px calc(1em + 15px) 9px 1em;
  appearance: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: pointer;
  border: none !important;
  width: 100%;
}

@media (min-width: 769px) {
  .c-select select {
    max-width: 210px;
  }
}

@media (max-width: 768px) {
  .c-select select {
    max-width: 100%;
  }
}

.c-select select::-ms-expand {
  display: none;
}

.c-select select.r-birthyear {
  min-width: 10em;
}

.c-select select.r-birthmonth {
  min-width: 6em;
}

.c-select select.r-birthday {
  min-width: 6em;
}

.c-select select.r-country {
  min-width: 15em;
}

/*
** プロダクト
*/
.p-footer {
  align-items: center;
  text-align: center;
  padding: 1em;
}

@media (min-width: 769px) {
  .p-footer {
    width: 1200px;
    margin-right: auto;
    margin-left: auto;
  }
}

.p-footer a {
  color: currentColor;
  text-decoration: none;
  font-size: 0.9em;
}

@media (min-width: 769px) {
  .p-breadcrumb {
    width: 1200px;
    margin: 0 auto;
  }
}

@media (max-width: 768px) {
  .p-breadcrumb {
    width: 100%;
  }
}

.p-breadcrumb__list {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  height: 50px;
  border: 1px solid;
  padding: 10px;
}

.p-headerbanner {
  width: 100%;
}

.p-headerbanner img {
  width: 100%;
}

.p-sitelogo {
  display: flex;
  align-items: center;
  max-width: 100%;
}

@media (min-width: 769px) {
  .p-sitelogo {
    height: 80px;
    padding: 0 22px;
  }
}

@media (max-width: 768px) {
  .p-sitelogo {
    height: 57px;
    padding: 0 10px;
  }
}

.p-sitelogo a {
  display: inline-flex;
  height: 100%;
}

.p-sitelogo img {
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
}

.p-pageTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

@media (min-width: 769px) {
  .p-pageTitle {
    font-size: 2.1rem;
  }
}

@media (max-width: 768px) {
  .p-pageTitle {
    font-size: 2.1rem;
  }
}

@media (min-width: 769px) {
  .p-pageContent {
    width: 1200px;
    margin: 0 auto;
    padding: 40px;
  }
}

@media (max-width: 768px) {
  .p-pageContent {
    padding: 16px;
  }
}

.p-serials {
  border-radius: 24px;
}

@media (min-width: 769px) {
  .p-serials {
    width: 760px;
    margin: 40px auto;
    padding: 40px 80px 46px;
  }
}

@media (max-width: 768px) {
  .p-serials {
    width: calc(100% - 40px);
    margin: 40px auto;
    padding: 6%;
  }
}

[data-view-mode="light"] .p-serials {
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

[data-view-mode="dark"] .p-serials {
  background-color: #1a1a1a;
  box-shadow: 0px 8px 56px 0px rgba(6, 0, 1, 0.9);
}

.p-serials_input dt {
  font-size: 22px;
  font-weight: bold;
  text-align: center;
}

.p-serials_input dt:first-child .p-serials_input dt ~ dt,
.p-serials_input dt ~ dd {
  margin-top: 20px;
  margin-bottom: 20px;
}

.p-serials_input dd ~ dt,
.p-serials_input dd ~ dd {
  margin-top: 10px;
}

.p-serials_input input {
  appearance: none;
  border: none;
  outline: none;
  border-radius: 10px;
  width: 100%;
  min-height: 72px;
  padding: 20px 1em;
}

[data-view-mode="light"] .p-serials_input input {
  color: #000;
  border: 1px solid #000;
  background-color: #fff;
}

[data-view-mode="dark"] .p-serials_input input {
  color: #fff;
  border: 1px solid #000;
  background-color: #252525;
}

.p-serials_button {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

@media (min-width: 769px) {
  .p-serials_button {
    margin-top: 72px;
  }
}

@media (max-width: 768px) {
  .p-serials_button {
    margin-top: 72px;
  }
}

.p-serials_button_item {
  display: inline-flex;
  gap: 20px;
}

@media (min-width: 769px) {
  .p-serials_button_item {
    width: 600px;
    min-height: 72px;
  }
}

@media (max-width: 768px) {
  .p-serials_button_item {
    width: 100%;
    min-height: 72px;
  }
}

.p-serials_button_item button, .p-serials_button_item input[type="submit"] {
  appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.p-serials_button_item a, .p-serials_button_item button, .p-serials_button_item input[type="submit"] {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-decoration: none;
  border-width: 1px;
  border-style: solid;
  border-radius: 36px;
  transition: .2s;
  position: relative;
}

@media (min-width: 769px) {
  .p-serials_button_item a, .p-serials_button_item button, .p-serials_button_item input[type="submit"] {
    font-size: 20px;
    padding: 1em 65px;
  }
}

@media (max-width: 768px) {
  .p-serials_button_item a, .p-serials_button_item button, .p-serials_button_item input[type="submit"] {
    font-size: 20px;
    padding: 1em 13%;
  }
}

.p-serials_button_item a:hover, .p-serials_button_item button:hover, .p-serials_button_item input[type="submit"]:hover {
  opacity: 1;
}

.p-serials_button_item.r-application input[type="submit"] {
  background-repeat: no-repeat;
  background-size: 26px 26px;
}

@media (min-width: 769px) {
  .p-serials_button_item.r-application input[type="submit"] {
    background-position: center right 30px;
  }
}

@media (max-width: 768px) {
  .p-serials_button_item.r-application input[type="submit"] {
    background-position: center right 7%;
  }
}

.p-serials_button_item.r-application a::after, .p-serials_button_item.r-application button::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translate(0%, -50%);
  background: no-repeat center center / 100% auto;
}

@media (min-width: 769px) {
  .p-serials_button_item.r-application a::after, .p-serials_button_item.r-application button::after {
    width: 26px;
    height: 26px;
  }
}

@media (max-width: 768px) {
  .p-serials_button_item.r-application a::after, .p-serials_button_item.r-application button::after {
    width: 26px;
    height: 26px;
  }
}

[data-view-mode="light"] .p-serials_button_item.r-application input[type="submit"] {
  background-image: url(../images/icon_button_on.svg);
}

[data-view-mode="light"] .p-serials_button_item.r-application input[type="submit"]:hover {
  background-image: url(../images/icon_button_off.svg);
}

[data-view-mode="light"] .p-serials_button_item.r-application [data-mode-target=button]::after {
  background-image: url(../images/icon_button_on.svg);
}

[data-view-mode="light"] .p-serials_button_item.r-application [data-mode-target=button]:hover::after {
  background-image: url(../images/icon_button_off.svg);
}

[data-view-mode="dark"] .p-serials_button_item.r-application input[type="submit"] {
  background-image: url(../images/icon_button_off.svg);
}

[data-view-mode="dark"] .p-serials_button_item.r-application input[type="submit"]:hover {
  background-image: url(../images/icon_button_on.svg);
}

[data-view-mode="dark"] .p-serials_button_item.r-application [data-mode-target="button"]::after {
  background-image: url(../images/icon_button_off.svg);
}

[data-view-mode="dark"] .p-serials_button_item.r-application [data-mode-target="button"]:hover::after {
  background-image: url(../images/icon_button_on.svg);
}

.p-input {
  border-radius: 16px;
}

@media (min-width: 769px) {
  .p-input {
    padding: 40px 80px 46px;
  }
}

@media (max-width: 768px) {
  .p-input {
    width: calc(100% - 20px);
    margin: 40px auto;
    padding: 6%;
  }
}

[data-view-mode="light"] .p-input {
  background-color: #fff;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

[data-view-mode="dark"] .p-input {
  background-color: #1a1a1a;
  box-shadow: 0px 8px 56px 0px rgba(6, 0, 1, 0.9);
}

.p-input label {
  display: block;
}

.p-input input[type="text"],
.p-input input[type="number"],
.p-input input[type="email"],
.p-input input[type="textarea"],
.p-input button {
  appearance: none;
  border: none;
  outline: none;
  border-radius: 10px;
}

.p-input input[type="text"],
.p-input input[type="number"],
.p-input input[type="email"],
.p-input input[type="textarea"],
.p-input button,
.p-input select {
  border: 1px solid;
}

[data-view-mode="light"] .p-input input[type="text"], [data-view-mode="light"]
.p-input input[type="number"], [data-view-mode="light"]
.p-input input[type="email"], [data-view-mode="light"]
.p-input input[type="textarea"], [data-view-mode="light"]
.p-input button, [data-view-mode="light"]
.p-input select {
  color: #333;
  border-color: #333;
}

[data-view-mode="dark"] .p-input input[type="text"], [data-view-mode="dark"]
.p-input input[type="number"], [data-view-mode="dark"]
.p-input input[type="email"], [data-view-mode="dark"]
.p-input input[type="textarea"], [data-view-mode="dark"]
.p-input button, [data-view-mode="dark"]
.p-input select {
  color: #fff;
  border-color: #fff;
}

.p-input select option {
  color: #333;
}

.p-input input[type="text"],
.p-input input[type="number"],
.p-input input[type="email"] {
  border-radius: 10px;
  padding: 10px 1em;
}

.p-input input[type="textarea"] {
  width: 100%;
  border-radius: 10px;
  padding: 20px 1em;
}

.p-input_anno {
  margin-bottom: 40px;
}

.p-input .required {
  color: red;
  font-size: 1.2em;
  line-height: 1;
}

.p-input_item + .p-input_item {
  margin-top: 40px;
}

.p-input_item_note {
  list-style: none;
  margin-bottom: 10px;
}

.p-input_item_note li {
  text-indent: -1.5em;
  padding-left: 1.5em;
}

.p-input_item_note li::before {
  content: "※";
  margin-right: .5em;
  text-indent: 0;
}

.p-input_item_title {
  margin-bottom: 1em;
}

@media (min-width: 769px) {
  .p-input_item_title {
    font-size: 20px;
  }
}

@media (max-width: 768px) {
  .p-input_item_title {
    font-size: 20px;
  }
}

.p-input_item_form_container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.p-input_item.r-name .p-input_item_form_container {
  flex-wrap: wrap;
}

@media (min-width: 769px) {
  .p-input_item.r-name .p-input_item_form_container {
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .p-input_item.r-name .p-input_item_form_container {
    gap: 8px;
  }
}

@media (min-width: 769px) {
  .p-input_item.r-name .p-input_item_form_container > label {
    flex: 0 1 calc(50% - 10px);
  }
}

@media (max-width: 768px) {
  .p-input_item.r-name .p-input_item_form_container > label {
    flex: 0 1 calc(50% - 4px);
  }
}

.p-input_item.r-name .p-input_item_form_container > label input {
  width: 100%;
  margin-top: 8px;
}

.p-input_item.r-name .p-input_item_form_container ~ .p-input_item_form_container {
  margin-top: 10px;
}

.p-input_item.r-email .p-input_item_form_container {
  flex-wrap: wrap;
}

@media (min-width: 769px) {
  .p-input_item.r-email .p-input_item_form_container {
    gap: 20px;
  }
}

@media (max-width: 768px) {
  .p-input_item.r-email .p-input_item_form_container {
    gap: 8px;
  }
}

.p-input_item.r-email .p-input_item_form_container input {
  width: 100%;
}

@media (min-width: 769px) {
  .p-input_item.r-email .p-input_item_form_container input {
    flex: 0 1 calc(50% - 10px);
  }
}

@media (max-width: 768px) {
  .p-input_item.r-email .p-input_item_form_container input {
    flex: 1 1 calc(50% - 4px);
  }
}

.p-input_item.r-telnumber .p-input_item_form_container {
  flex-wrap: wrap;
}

@media (min-width: 769px) {
  .p-input_item.r-telnumber .p-input_item_form_container {
    align-items: center;
    gap: 10px;
  }
}

@media (max-width: 768px) {
  .p-input_item.r-telnumber .p-input_item_form_container {
    align-items: center;
    gap: 10px;
  }
}

@media (min-width: 769px) {
  .p-input_item.r-telnumber .p-input_item_form_container input {
    width: 140px;
  }
}

@media (max-width: 768px) {
  .p-input_item.r-telnumber .p-input_item_form_container input {
    flex: 1 1 20%;
    width: 50%;
  }
}

@media (max-width: 768px) {
  .p-input_item.r-telnumber .p-input_item_form_container input:nth-of-type(1) {
    width: 30%;
    max-width: 4em;
  }
}

@media (max-width: 768px) {
  .p-input_item.r-telnumber .p-input_item_form_container input:nth-of-type(2) {
    width: 30%;
    max-width: 6em;
  }
}

@media (max-width: 768px) {
  .p-input_item.r-telnumber .p-input_item_form_container input:nth-of-type(3) {
    width: 30%;
    max-width: 6em;
  }
}

.p-input_item.r-telnumber .p-input_item_form_container .hyphen {
  width: 10px;
  height: 1px;
}

@media (max-width: 768px) {
  .p-input_item.r-telnumber .p-input_item_form_container .hyphen {
    width: 5px;
  }
}

[data-view-mode="light"] .p-input_item.r-telnumber .p-input_item_form_container .hyphen {
  background-color: #333;
}

[data-view-mode="dark"] .p-input_item.r-telnumber .p-input_item_form_container .hyphen {
  background-color: #fff;
}

.p-input_item.r-birthdate .p-input_item_form_container {
  flex-wrap: wrap;
}

@media (min-width: 769px) {
  .p-input_item.r-birthdate .p-input_item_form_container {
    gap: 10px;
  }
}

@media (max-width: 768px) {
  .p-input_item.r-birthdate .p-input_item_form_container {
    flex-direction: column;
    gap: 8px;
  }
}

.p-input_item.r-birthdate .p-input_item_form_container .c-select.r-birthyear {
  min-width: 10em;
}

@media (max-width: 768px) {
  .p-input_item.r-birthdate .p-input_item_form_container .c-select.r-birthyear {
    width: 50%;
  }
}

.p-input_item.r-birthdate .p-input_item_form_container .c-select.r-birthmonth {
  min-width: 8em;
}

@media (max-width: 768px) {
  .p-input_item.r-birthdate .p-input_item_form_container .c-select.r-birthmonth {
    width: 50%;
  }
}

.p-input_item.r-birthdate .p-input_item_form_container .c-select.r-birthday {
  min-width: 8em;
}

@media (max-width: 768px) {
  .p-input_item.r-birthdate .p-input_item_form_container .c-select.r-birthday {
    width: 50%;
  }
}

.p-input_item.r-birthdate .p-input_item_form_container .c-select.r-country {
  min-width: 15em;
}

.p-input_item.r-address .p-input_item_form_container {
  gap: 10px;
  flex-direction: column;
}

.p-input_item.r-address .p-input_item_form_container > div {
  align-items: center;
  margin-top: 8px;
}

@media (min-width: 769px) {
  .p-input_item.r-address .p-input_item_form_container > div {
    display: inline-flex;
    flex: 0 1 auto;
  }
}

@media (max-width: 768px) {
  .p-input_item.r-address .p-input_item_form_container > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 1 100%;
  }
}

@media (max-width: 768px) {
  .p-input_item.r-address .p-input_item_form_container > div input, .p-input_item.r-address .p-input_item_form_container > div select {
    flex: 1 1 100%;
  }
}

@media (min-width: 769px) {
  .p-input_item.r-address .p-input_item_form_container > div.r-postal input {
    width: 210px;
    margin-left: 24px;
  }
}

@media (max-width: 768px) {
  .p-input_item.r-address .p-input_item_form_container > div.r-postal input {
    width: 50%;
  }
}

@media (min-width: 769px) {
  .p-input_item.r-address .p-input_item_form_container > div.r-pref .c-select {
    width: 210px;
    margin-left: 104px;
  }
}

@media (min-width: 769px) {
  .p-input_item.r-address .p-input_item_form_container > div.r-pref .c-select select {
    width: 211px;
  }
}

@media (min-width: 769px) {
  .p-input_item.r-address .p-input_item_form_container > div.r-cities input {
    width: 210px;
    margin-left: 120px;
  }
}

@media (max-width: 768px) {
  .p-input_item.r-address .p-input_item_form_container > div.r-cities input {
    width: 100%;
  }
}

@media (min-width: 769px) {
  .p-input_item.r-address .p-input_item_form_container > div.r-housenumber input {
    width: 210px;
    margin-left: 136px;
  }
}

@media (max-width: 768px) {
  .p-input_item.r-address .p-input_item_form_container > div.r-housenumber input {
    width: 100%;
  }
}

@media (min-width: 769px) {
  .p-input_item.r-address .p-input_item_form_container > div.r-building input {
    width: 210px;
    margin-left: 41px;
  }
}

@media (max-width: 768px) {
  .p-input_item.r-address .p-input_item_form_container > div.r-building input {
    width: 100%;
  }
}

.p-input_item.r-text .p-input_item_form_container {
  gap: 20px;
  flex-wrap: wrap;
}

.p-input_item.r-text .p-input_item_form_container input {
  width: 100%;
}

@media (min-width: 769px) {
  .p-input_item.r-text .p-input_item_form_container input {
    flex: 0 1 calc(50% - 10px);
  }
}

@media (max-width: 768px) {
  .p-input_item.r-text .p-input_item_form_container input {
    flex: 0 1 100%;
  }
}

.p-input_item.r-select .p-input_item_form_container {
  flex-wrap: wrap;
}

@media (min-width: 769px) {
  .p-input_item.r-select .p-input_item_form_container {
    gap: 10px;
  }
}

@media (max-width: 768px) {
  .p-input_item.r-select .p-input_item_form_container {
    gap: 8px 4%;
  }
}

.p-input_item.r-radio .p-input_item_form_container {
  gap: 10px 20px;
  flex-wrap: wrap;
}

.p-input_item.r-radio .p-input_item_form_container > label {
  text-indent: -21px;
  padding-left: 21px;
}

@media (min-width: 769px) {
  .p-input_item.r-radio .p-input_item_form_container > label {
    flex: 0 1 auto;
  }
}

@media (max-width: 768px) {
  .p-input_item.r-radio .p-input_item_form_container > label {
    flex: 0 1 auto;
  }
}

.p-input_item.r-radio .p-input_item_form_container > label input {
  vertical-align: top;
  margin-top: 6px;
  margin-right: 8px;
}

.p-input_item.r-checkbox .p-input_item_form_container {
  gap: 10px 20px;
  flex-wrap: wrap;
}

.p-input_item.r-checkbox .p-input_item_form_container > label {
  display: inline-flex;
  align-items: center;
}

@media (min-width: 769px) {
  .p-input_item.r-checkbox .p-input_item_form_container > label {
    flex: 0 1 auto;
  }
}

@media (max-width: 768px) {
  .p-input_item.r-checkbox .p-input_item_form_container > label {
    flex: 0 1 auto;
  }
}

.p-input_item.r-checkbox .p-input_item_form_container > label input {
  margin: 0;
  margin-right: 5px;
}

.p-input_item.r-fileupload .p-input_item_form_container {
  gap: 10px 10px;
  flex-wrap: wrap;
}

.p-input_item.r-fileupload .p-input_item_form_container .fileupload_container {
  padding: 10px 20px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid;
}

.p-input_item.r-fileupload .p-input_item_form_container label {
  padding: 10px 40px;
  border-radius: 10px;
  cursor: pointer;
  border: 1px solid;
}

.p-input_item.r-fileupload .p-input_item_form_container label:hover {
  opacity: .8;
  transition: opacity .2s;
}

[data-view-mode="light"] .p-input_item.r-fileupload .p-input_item_form_container label {
  background-color: #00489E;
  color: #fff;
  border-color: #333;
}

[data-view-mode="dark"] .p-input_item.r-fileupload .p-input_item_form_container label {
  background-color: #fff;
  color: #202124;
  border-color: #fff;
}

.p-input_item.r-fileupload .p-input_item_form_container input[type="file"] {
  display: none;
}

.p-input_item.r-fileupload .p-input_item_form_container .display_filename {
  display: flex;
  align-items: center;
  border: 1px solid;
  border-radius: 10px;
  padding: 10px 1em;
  min-height: 46px;
}

@media (min-width: 769px) {
  .p-input_item.r-fileupload .p-input_item_form_container .display_filename {
    width: 320px;
  }
}

@media (max-width: 768px) {
  .p-input_item.r-fileupload .p-input_item_form_container .display_filename {
    width: 100%;
  }
}

[data-view-mode="light"] .p-input_item.r-fileupload .p-input_item_form_container .display_filename {
  border-color: #333;
}

[data-view-mode="dark"] .p-input_item.r-fileupload .p-input_item_form_container .display_filename {
  border-color: #fff;
}

.p-input_item.r-agree .p-input_item_form_container {
  gap: 10px 20px;
  flex-wrap: wrap;
}

.p-input_item.r-agree .p-input_item_form_container > label {
  display: inline-flex;
  align-items: center;
}

@media (min-width: 769px) {
  .p-input_item.r-agree .p-input_item_form_container > label {
    flex: 0 1 auto;
  }
}

@media (max-width: 768px) {
  .p-input_item.r-agree .p-input_item_form_container > label {
    flex: 0 1 auto;
  }
}

.p-input_item.r-agree .p-input_item_form_container > label input {
  margin: 0;
  margin-right: 5px;
}

.p-input_item .error {
  border-color: red !important;
}

.p-input_confirm {
  flex: 1 0 calc(50% - 5px);
  font-weight: 100;
  border: 1px solid;
  border-color: #ccc;
  border-radius: 6px;
  padding: 10px;
}

.p-input_confirm span {
  display: block;
  font-size: 12px;
  font-weight: normal;
}

.p-buttonSubmit {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
}

@media (min-width: 769px) {
  .p-buttonSubmit {
    margin-top: 72px;
  }
}

@media (max-width: 768px) {
  .p-buttonSubmit {
    margin-top: 72px;
  }
}

.p-buttonSubmit_item {
  display: inline-flex;
  gap: 20px;
}

@media (min-width: 769px) {
  .p-buttonSubmit_item {
    min-height: 72px;
  }
}

@media (max-width: 768px) {
  .p-buttonSubmit_item {
    min-height: 72px;
  }
}

.p-buttonSubmit_item button, .p-buttonSubmit_item input[type="submit"], .p-buttonSubmit_item input[type="button"] {
  appearance: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.p-buttonSubmit_item a, .p-buttonSubmit_item button, .p-buttonSubmit_item input[type="submit"], .p-buttonSubmit_item input[type="button"] {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  text-decoration: none;
  border-width: 1px;
  border-style: solid;
  border-radius: 36px;
  transition: .2s;
  position: relative;
}

@media (min-width: 769px) {
  .p-buttonSubmit_item a, .p-buttonSubmit_item button, .p-buttonSubmit_item input[type="submit"], .p-buttonSubmit_item input[type="button"] {
    font-size: 20px;
    padding: 1em 65px;
  }
}

@media (max-width: 768px) {
  .p-buttonSubmit_item a, .p-buttonSubmit_item button, .p-buttonSubmit_item input[type="submit"], .p-buttonSubmit_item input[type="button"] {
    font-size: 20px;
    padding: 1em 13%;
  }
}

.p-buttonSubmit_item a:hover, .p-buttonSubmit_item button:hover, .p-buttonSubmit_item input[type="submit"]:hover, .p-buttonSubmit_item input[type="button"]:hover {
  opacity: .9;
}

.p-buttonSubmit_item.r-application input[type="submit"], .p-buttonSubmit_item.r-application input[type="button"], .p-buttonSubmit_item.r-next input[type="submit"], .p-buttonSubmit_item.r-next input[type="button"] {
  background-repeat: no-repeat;
  background-size: 26px 26px;
}

@media (min-width: 769px) {
  .p-buttonSubmit_item.r-application input[type="submit"], .p-buttonSubmit_item.r-application input[type="button"], .p-buttonSubmit_item.r-next input[type="submit"], .p-buttonSubmit_item.r-next input[type="button"] {
    background-position: center right 30px;
  }
}

@media (max-width: 768px) {
  .p-buttonSubmit_item.r-application input[type="submit"], .p-buttonSubmit_item.r-application input[type="button"], .p-buttonSubmit_item.r-next input[type="submit"], .p-buttonSubmit_item.r-next input[type="button"] {
    background-position: center right 7%;
  }
}

.p-buttonSubmit_item.r-application a::after, .p-buttonSubmit_item.r-application button::after, .p-buttonSubmit_item.r-next a::after, .p-buttonSubmit_item.r-next button::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translate(0%, -50%);
  background: no-repeat center center / 100% auto;
}

@media (min-width: 769px) {
  .p-buttonSubmit_item.r-application a::after, .p-buttonSubmit_item.r-application button::after, .p-buttonSubmit_item.r-next a::after, .p-buttonSubmit_item.r-next button::after {
    width: 26px;
    height: 26px;
  }
}

@media (max-width: 768px) {
  .p-buttonSubmit_item.r-application a::after, .p-buttonSubmit_item.r-application button::after, .p-buttonSubmit_item.r-next a::after, .p-buttonSubmit_item.r-next button::after {
    width: 26px;
    height: 26px;
  }
}

.p-buttonSubmit_item.r-prev input[type="submit"], .p-buttonSubmit_item.r-prev input[type="button"] {
  background-repeat: no-repeat;
  background-size: 26px 26px;
}

@media (min-width: 769px) {
  .p-buttonSubmit_item.r-prev input[type="submit"], .p-buttonSubmit_item.r-prev input[type="button"] {
    background-position: center left 30px;
  }
}

@media (max-width: 768px) {
  .p-buttonSubmit_item.r-prev input[type="submit"], .p-buttonSubmit_item.r-prev input[type="button"] {
    background-position: center left 7%;
  }
}

.p-buttonSubmit_item.r-prev a::after, .p-buttonSubmit_item.r-prev button::after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 30px;
  transform: translate(0%, -50%);
  background: no-repeat center center / 100% auto;
}

@media (min-width: 769px) {
  .p-buttonSubmit_item.r-prev a::after, .p-buttonSubmit_item.r-prev button::after {
    width: 26px;
    height: 26px;
  }
}

@media (max-width: 768px) {
  .p-buttonSubmit_item.r-prev a::after, .p-buttonSubmit_item.r-prev button::after {
    width: 26px;
    height: 26px;
  }
}

@media (min-width: 769px) {
  .p-buttonSubmit_item.r-application {
    width: 600px;
    min-height: 72px;
  }
}

@media (max-width: 768px) {
  .p-buttonSubmit_item.r-application {
    width: 100%;
    min-height: 72px;
  }
}

@media (min-width: 769px) {
  .p-buttonSubmit_item.r-next {
    width: 500px;
    min-height: 72px;
  }
}

@media (max-width: 768px) {
  .p-buttonSubmit_item.r-next {
    width: 100%;
    min-height: 72px;
  }
}

@media (min-width: 769px) {
  .p-buttonSubmit_item.r-prev {
    width: 300px;
    min-height: 72px;
  }
}

@media (max-width: 768px) {
  .p-buttonSubmit_item.r-prev {
    width: 100%;
    min-height: 72px;
  }
}

[data-view-mode="light"] .p-buttonSubmit_item.r-application input[type="submit"], [data-view-mode="light"] .p-buttonSubmit_item.r-application input[type="button"], [data-view-mode="light"] .p-buttonSubmit_item.r-next input[type="submit"], [data-view-mode="light"] .p-buttonSubmit_item.r-next input[type="button"] {
  background-image: url(../images/icon_button_on.svg);
}

[data-view-mode="light"] .p-buttonSubmit_item.r-application input[type="submit"]:hover, [data-view-mode="light"] .p-buttonSubmit_item.r-application input[type="button"]:hover, [data-view-mode="light"] .p-buttonSubmit_item.r-next input[type="submit"]:hover, [data-view-mode="light"] .p-buttonSubmit_item.r-next input[type="button"]:hover {
  background-image: url(../images/icon_button_off.svg);
}

[data-view-mode="light"] .p-buttonSubmit_item.r-application [data-mode-target=button]::after, [data-view-mode="light"] .p-buttonSubmit_item.r-next [data-mode-target=button]::after {
  background-image: url(../images/icon_button_on.svg);
}

[data-view-mode="light"] .p-buttonSubmit_item.r-application [data-mode-target=button]:hover::after, [data-view-mode="light"] .p-buttonSubmit_item.r-next [data-mode-target=button]:hover::after {
  background-image: url(../images/icon_button_off.svg);
}

[data-view-mode="light"] .p-buttonSubmit_item.r-prev input[type="submit"], [data-view-mode="light"] .p-buttonSubmit_item.r-prev input[type="button"] {
  background-image: url(../images/icon_button2_off.svg);
}

[data-view-mode="light"] .p-buttonSubmit_item.r-prev input[type="submit"]:hover, [data-view-mode="light"] .p-buttonSubmit_item.r-prev input[type="button"]:hover {
  background-image: url(../images/icon_button2_on.svg);
}

[data-view-mode="light"] .p-buttonSubmit_item.r-prev [data-mode-target=button]::after {
  background-image: url(../images/icon_button2_off.svg);
}

[data-view-mode="light"] .p-buttonSubmit_item.r-prev [data-mode-target=button]:hover::after {
  background-image: url(../images/icon_button2_on.svg);
}

[data-view-mode="dark"] .p-buttonSubmit_item.r-application input[type="submit"], [data-view-mode="dark"] .p-buttonSubmit_item.r-application input[type="button"], [data-view-mode="dark"] .p-buttonSubmit_item.r-next input[type="submit"], [data-view-mode="dark"] .p-buttonSubmit_item.r-next input[type="button"] {
  background-image: url(../images/icon_button_off.svg);
}

[data-view-mode="dark"] .p-buttonSubmit_item.r-application input[type="submit"]:hover, [data-view-mode="dark"] .p-buttonSubmit_item.r-application input[type="button"]:hover, [data-view-mode="dark"] .p-buttonSubmit_item.r-next input[type="submit"]:hover, [data-view-mode="dark"] .p-buttonSubmit_item.r-next input[type="button"]:hover {
  background-image: url(../images/icon_button_on.svg);
}

[data-view-mode="dark"] .p-buttonSubmit_item.r-application [data-mode-target="button"]::after, [data-view-mode="dark"] .p-buttonSubmit_item.r-next [data-mode-target="button"]::after {
  background-image: url(../images/icon_button_off.svg);
}

[data-view-mode="dark"] .p-buttonSubmit_item.r-application [data-mode-target="button"]:hover::after, [data-view-mode="dark"] .p-buttonSubmit_item.r-next [data-mode-target="button"]:hover::after {
  background-image: url(../images/icon_button_on.svg);
}

[data-view-mode="dark"] .p-buttonSubmit_item.r-prev input[type="submit"], [data-view-mode="dark"] .p-buttonSubmit_item.r-prev input[type="button"] {
  background-image: url(../images/icon_button2_on.svg);
}

[data-view-mode="dark"] .p-buttonSubmit_item.r-prev input[type="submit"]:hover, [data-view-mode="dark"] .p-buttonSubmit_item.r-prev input[type="button"]:hover {
  background-image: url(../images/icon_button2_off.svg);
}

[data-view-mode="dark"] .p-buttonSubmit_item.r-prev [data-mode-target="button"]::after {
  background-image: url(../images/icon_button2_on.svg);
}

[data-view-mode="dark"] .p-buttonSubmit_item.r-prev [data-mode-target="button"]:hover::after {
  background-image: url(../images/icon_button2_off.svg);
}

[data-bg-color="pageTitle"] {
  background-color: #fff200;
}

[data-bg-color="discription"] {
  background-color: #fffec1;
}

[data-view-mode="light"] {
  background-color: #fff;
}

[data-view-mode="light"] [data-mode-target="color"] {
  color: #333;
}

[data-view-mode="light"] [data-mode-target="button"] {
  background-color: #fff;
  color: #333;
  border-color: #333;
}

[data-view-mode="light"] [data-mode-target="button"]:hover {
  background-color: #333;
  color: #fff;
  border-color: #333;
}

[data-view-mode="light"] [data-mode-target="button2"] {
  background-color: #333;
  color: #fff;
  border-color: #333;
}

[data-view-mode="light"] [data-mode-target="button2"]:hover {
  background-color: #fff;
  color: #333;
  border-color: #333;
}

[data-view-mode="dark"] {
  background-color: #000;
}

[data-view-mode="dark"] [data-mode-target="color"] {
  color: #fff;
}

[data-view-mode="dark"] [data-mode-target="button"] {
  background-color: #000;
  color: #fff;
  border-color: #fff;
}

[data-view-mode="dark"] [data-mode-target="button"]:hover {
  background-color: #fff;
  color: #333;
  border-color: #fff;
}

[data-view-mode="dark"] [data-mode-target="button2"] {
  background-color: #fff;
  color: #333;
  border-color: #fff;
}

[data-view-mode="dark"] [data-mode-target="button2"]:hover {
  background-color: #000;
  color: #fff;
  border-color: #fff;
}

.p-live {
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  padding: 0 80px;
  width: 100%;
}

@media (max-width: 768px) {
  .p-live {
    display: block;
  }
}

.p-livePlayer {
  width: 55%;
}

@media (max-width: 768px) {
  .p-livePlayer {
    width: 100%;
  }
}

.p-playerVideo {
  height: 0px;
  padding-bottom: 56.25%;
  position: relative;
  width: 100%;
}

.p-liveInfo {
  width: calc(45% - 64px);
}

@media (max-width: 768px) {
  .p-liveInfo {
    margin-top: 40px;
    width: 100%;
  }
}

.p-liveInfoBox {
  border-radius: 15px;
  box-sizing: border-box;
  padding: 38px 32px;
  width: 100%;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .p-liveInfoBox {
    padding: 30px 16px;
  }
}

.p-liveInfoBoxTtl {
  background-size: 30px 20px;
  background-position: left center;
  background-repeat: no-repeat;
  font-size: 1.8rem;
  font-weight: bold;
}

[data-view-mode="light"] [data-mode-target="color"] .p-liveInfoBox {
  background-color: #fff;
}

[data-view-mode="dark"] [data-mode-target="color"] .p-liveInfoBox {
  background-color: #1a1a1a;
  color: #fff;
}

/*
** ユーティリティ
*/
.is-errorTitle {
  font-size: 16px;
  color: red !important;
  margin-bottom: 32px;
  padding: 16px;
  box-shadow: 0 0 0 1px red inset;
}

.is-attentionTitle {
  font-size: 16px;
  color: #333 !important;
  margin-bottom: 32px;
  padding: 16px;
  box-shadow: 0 0 0 1px #333 inset;
}

.is-noteTitle {
  font-size: 16px;
  color: #333 !important;
  margin-bottom: 32px;
  padding: 16px;
  box-shadow: 0 0 0 1px #333 inset;
}

.is-inputError {
  box-shadow: 0 0 0 1px red inset !important;
}

.is-error {
  font-size: 14px !important;
  color: red !important;
}

.is-note {
  font-size: 14px !important;
  color: #333 !important;
}

.u-color--blue {
  color: #00b4ed !important;
}

.u-color--error {
  color: red !important;
}
