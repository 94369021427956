@font-face {
  font-family: "NotoSans";
  src: url("../font/NotoSansJP-Medium.woff2") format("woff2"), url("../font/NotoSansJP-Medium.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "NotoSans";
  src: url("../font/NotoSansJP-Regular.woff2") format("woff2"), url("../font/NotoSansJP-Regular.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}

