.p-buttonSubmit {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  @include pc{
    margin-top: 72px;
  }
  @include sp{
    margin-top: 72px;
  }
  &_item {
    display: inline-flex;
    gap: 20px;
    @include pc{
      min-height: 72px;
    }
    @include sp{
      min-height: 72px;
    }

    input[type="submit"], input[type="button"] {
      // background-repeat: no-repeat;
      // background-position: center right 30px;
      // background-size: 26px 26px;
    }
    button, input[type="submit"], input[type="button"] {
      appearance: none;
      border: none;
      outline: none;
      cursor: pointer;
    }
    a, button, input[type="submit"], input[type="button"] {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      text-decoration: none;
      border-width: 1px;
      border-style: solid;
      border-radius: 36px;
      transition: .2s;
      position: relative;
      @include pc{
        font-size: 20px;
        padding: 1em 65px;
      }
      @include sp{
        font-size: 20px;
        padding: 1em 13%;
      }
      &:hover {
        opacity: .9;
      }
    }

    &.r-application,
    &.r-next{
      input[type="submit"], input[type="button"] {
        background-repeat: no-repeat;
        background-size: 26px 26px;
        @include pc{
          background-position: center right 30px;
        }
        @include sp{
          background-position: center right 7%;
        }
      }
      a, button{
        &::after {
          content: "";
          display: inline-block;
          position: absolute;
          top: 50%;
          right: 30px;
          transform: translate(0%, -50%);
          background: no-repeat center center / 100% auto;

          @include pc{
            width: 26px;
            height: 26px;
          }
          @include sp{
            width: 26px;
            height: 26px;
          }
        }
      }
    }
    &.r-prev{
      @include pc{

      }
      @include sp{
        // order: 2;
      }
      input[type="submit"], input[type="button"] {
        background-repeat: no-repeat;
        background-size: 26px 26px;
        @include pc{
          background-position: center left 30px;
        }
        @include sp{
          background-position: center left 7%;
        }
      }
      a, button{
        &::after {
          content: "";
          display: inline-block;
          position: absolute;
          top: 50%;
          right: 30px;
          transform: translate(0%, -50%);
          background: no-repeat center center / 100% auto;

          @include pc{
            width: 26px;
            height: 26px;
          }
          @include sp{
            width: 26px;
            height: 26px;
          }
        }
      }
    }
    &.r-application {
      @include pc{
        width: 600px;
        min-height: 72px;
      }
      @include sp{
        width: 100%;
        min-height: 72px;
      }
    }
    &.r-next {
      @include pc{
        width: 500px;
        min-height: 72px;
      }
      @include sp{
        width: 100%;
        min-height: 72px;
      }
    }
    &.r-prev {
      @include pc{
        width: 300px;
        min-height: 72px;
      }
      @include sp{
        width: 100%;
        min-height: 72px;
      }
    }
    // ダークモード
    [data-view-mode="light"] & {
      // アイコン切り替え
      &.r-application,
      &.r-next {
        input[type="submit"], input[type="button"] {
          background-image: url(../images/icon_button_on.svg);
          &:hover{
            background-image: url(../images/icon_button_off.svg);
          }
        }
        [data-mode-target=button] {
          &::after {
            background-image: url(../images/icon_button_on.svg);
          }
          &:hover::after {
            background-image: url(../images/icon_button_off.svg);
          }
        }
      }
      &.r-prev {
        input[type="submit"], input[type="button"] {
          background-image: url(../images/icon_button2_off.svg);
          &:hover{
            background-image: url(../images/icon_button2_on.svg);
          }
        }
        [data-mode-target=button] {
          &::after {
            background-image: url(../images/icon_button2_off.svg);
          }
          &:hover::after {
            background-image: url(../images/icon_button2_on.svg);
          }
        }
      }
    }
    [data-view-mode="dark"] & {
      // アイコン切り替え
      &.r-application,
      &.r-next {
        input[type="submit"], input[type="button"] {
          background-image: url(../images/icon_button_off.svg);
          &:hover{
            background-image: url(../images/icon_button_on.svg);
          }
        }
        [data-mode-target="button"] {
          &::after {
            background-image: url(../images/icon_button_off.svg);
          }
          &:hover::after {
            background-image: url(../images/icon_button_on.svg);
          }
        }
      }
      &.r-prev {
        input[type="submit"], input[type="button"] {
          background-image: url(../images/icon_button2_on.svg);
          &:hover{
            background-image: url(../images/icon_button2_off.svg);
          }
        }
        [data-mode-target="button"] {
          &::after {
            background-image: url(../images/icon_button2_on.svg);
          }
          &:hover::after {
            background-image: url(../images/icon_button2_off.svg);
          }
        }
      }

    }


  }
}
