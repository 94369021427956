[data-view-mode="light"] {
  // TODO: 開発用 カラー設定
  background-color: $bgColor-mode-light;
  [data-mode-target="color"] {
    // background-color: inherit;
    color: $color-font1;
    // * {
    //   color: inherit;
    // }
  }
  [data-mode-target="button"] {
    background-color: $button-color2;
    color: $button-color1;
    border-color: $button-color1;
    &:hover {
      background-color: $button-color1;
      color: $button-color2;
      border-color: $button-color1;
    }
  }
  [data-mode-target="button2"] {
    background-color: $button-color1;
    color: $button-color2;
    border-color: $button-color1;
    &:hover {
      background-color: $button-color2;
      color: $button-color1;
      border-color: $button-color1;
    }
  }


}
[data-view-mode="dark"] {
  // TODO: 開発用 カラー設定
  background-color: $bgColor-mode-dark;
  [data-mode-target="color"] {
    // background-color: inherit;
    color: $color-font2;
    // * {
    //   color: inherit;
    // }
  }

  [data-mode-target="button"] {
    background-color: $bgColor-mode-dark;
    color: $button-color2;
    border-color: $button-color2;
    &:hover {
      background-color: $button-color2;
      color: $button-color1;
      border-color: $button-color2;
    }
  }
  [data-mode-target="button2"] {
    background-color: $button-color2;
    color: $button-color1;
    border-color: $button-color2;
    &:hover {
      background-color: $bgColor-mode-dark;
      color: $button-color2;
      border-color: $button-color2;
    }
  }
}