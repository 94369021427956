/*-----------------------------------------------
	Browser Reset
-----------------------------------------------*/
body {
	margin: 0;
	padding: 0;
}
h1,h2,h3,h4,h5,h6,pre,address,em,strong,th,ins {
	font-size: 100%;
	font-weight: normal;
}
h1,h2,h3,h4,h5,h6,pre,address,ul,ol,li,dl,dt,dd,form,legend,p {
	margin: 0;
	padding: 0;
}
table,tr, td, th {
	margin: 0;
	padding: 0;
	border-collapse: collapse;
	border: 0;
}
a, button{
	word-break: break-all;
}
img {
    border: none;
}
ul:not([class]), ol:not([class]) {
  // list-style: none;
	text-indent: -1em;
	padding-left: 1em;
	li {
		text-indent: 0;
	}
}
header, section, footer, aside, nav, article, figure, main {
	margin: 0;
	padding: 0;
	display: block;
}
body,th,td {
	word-wrap : break-word;
}
button,
input,
select,
textarea {
	font-family: inherit;
	font-size: inherit;
	background: none;
	vertical-align: bottom;
}
input[type=radio]{
	margin: 0;
	vertical-align: middle;
}
button{
	appearance: none;
	border: none;
	outline: none;
	&:focus{
		outline: inherit;
	}
}
video{
	max-width: 100%;
	vertical-align: bottom;
}
::placeholder{
	font-family: Arial;
}