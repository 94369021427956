.p-input {
  // border: 1px solid #ccc;
  // margin-right: auto;
  // margin-left: auto;
  border-radius: 16px;
  @include pc{
    // width: #{$contents-width}px;
    padding: 40px 80px 46px;
  }
  @include sp{
    width: calc(100% - 20px);
    margin: 40px auto;
    padding: 6%;
  }
  [data-view-mode="light"] & {
    background-color: $bgColor-mode-light;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);

  }
  [data-view-mode="dark"] & {
    background-color: rgb(26, 26, 26);
    box-shadow: 0px 8px 56px 0px rgba(6, 0, 1, 0.9);
  }
  // リセット
  label {
    display: block;
  }
  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="textarea"],
  button {
    appearance: none;
    border: none;
    outline: none;
    border-radius: 10px;

  }
  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="textarea"],
  button,
  select {
    border: 1px solid;
    [data-view-mode="light"] & {
      color: #333;
      border-color: #333;
    }
    [data-view-mode="dark"] & {
      color: #fff;
      border-color:  #fff;
    }
  }
  select {
    option {
      color: #333;
    }
  }
  input[type="text"],
  input[type="number"],
  input[type="email"] {
    // width: 100%;
    // min-height: 40px;
    border-radius: 10px;
    padding: 10px 1em;
  }
  input[type="textarea"] {
    width: 100%;
    // min-height: 40px;
    border-radius: 10px;
    padding: 20px 1em;
  }
  input[type="text"],
  input[type="number"],
  input[type="email"],
  input[type="textarea"],
  select {

  }
  &_anno {
    // color: #666;
    margin-bottom: 40px;

  }
  .required {
    color: red;
    font-size: 1.2em;
    line-height: 1;

    // color: red !important;
    // font-size: 1.2em;
    // line-height: 1.5;
    // font-size: .7em;
    // border: 1px solid red;
    // padding: 0px 8px;
    // display: inline-flex;
    // justify-content: center;
    // align-items: flex-start;
    // margin-left: 8px;
  }

  &_item {
    + .p-input_item {
      margin-top: 40px;
    }
    &_note {
      list-style: none;
      margin-bottom: 10px;
      li {
        text-indent: -1.5em;
        padding-left: 1.5em;
        &::before {
          content: "※";
          margin-right: .5em;
          text-indent: 0;
        }
        & + li{

        }
      }
    }
    &_title {
      // background-color: #ebebeb;
      // border-radius: 10px;
      margin-bottom: 1em;
      // padding: 10px 10px 10px 20px;
      @include pc{
        font-size: 20px;
      }
      @include sp{
        font-size: 20px;
      }
    }
    &_form {
      // padding-right: 10px;
      // padding-left: 10px;
      &_container {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
      }
    }
    // フォーム：お名前
    &.r-name {
      .p-input_item_form {
        &_container {
          flex-wrap: wrap;
          @include pc{
            gap: 20px;
          }
          @include sp{
            gap: 8px;
          }
          & > label {
            @include pc{
              flex: 0 1 calc(50% - 10px);
            }
            @include sp{
              flex: 0 1 calc(50% - 4px);
            }
            input {
              width: 100%;
              margin-top: 8px;
            }
          }
        }
        &_container {
          & ~ .p-input_item_form_container {
            margin-top: 10px;
          }
        }
      }
    }

    // フォーム：メールアドレス
    &.r-email {
      .p-input_item_form {
        &_container {
          flex-wrap: wrap;
          @include pc{
            gap: 20px;
          }
          @include sp{
            gap: 8px;
          }
          input {
            width: 100%;
            @include pc{
              flex: 0 1 calc(50% - 10px);
            }
            @include sp{
              flex: 1 1 calc(50% - 4px);
            }
          }
        }
      }
    }

    // フォーム：電話番号
    &.r-telnumber {
      .p-input_item_form {
        &_container {
          flex-wrap: wrap;
          @include pc{
            align-items: center;
            gap: 10px;
          }
          @include sp{
            // flex-direction: column;
            align-items: center;
            gap: 10px;
          }
          input {
            // text-align: center;
            @include pc{
              width: 140px;
            }
            @include sp{
              flex: 1 1 20%;
              width: 50%;
            }
            &:nth-of-type(1) {
              @include sp{
                width: 30%;
                max-width: 4em;
              }
            }
            &:nth-of-type(2) {
              @include sp{
                width: 30%;
                max-width: 6em;
              }
            }
            &:nth-of-type(3) {
              @include sp{
                width: 30%;
                max-width: 6em;
              }
            }
          }
          .hyphen {
            width: 10px;
            height: 1px;
            @include sp{
              width: 5px;
            }
            [data-view-mode="light"] & {
              background-color: $button-color1;
            }
            [data-view-mode="dark"] & {
              background-color: #fff;
            }
          }

        }
      }
    }

    // フォーム：生年月日
    &.r-birthdate {
      .p-input_item_form {
        &_container {
          flex-wrap: wrap;
          @include pc{
            gap: 10px;
          }
          @include sp{
            flex-direction: column;
            gap: 8px;
          }
          .c-select {
            &.r-birthyear {
              min-width: 10em;
              @include sp{
                width: 50%;
              }
            }
            &.r-birthmonth {
              min-width: 8em;
              @include sp{
                width: 50%;
              }
            }
            &.r-birthday {
              min-width: 8em;
              @include sp{
                width: 50%;
              }
            }
            &.r-country {
              min-width: 15em;
            }
          }

        }
      }
    }

    // フォーム：住所
    &.r-address {
      .p-input_item_form {
        &_container {
          gap: 10px;
          flex-direction: column;
          @include pc{
          }
          @include sp{
          }
          & > div {
            align-items: center;
            margin-top: 8px;
            @include pc{
              display: inline-flex;
              flex: 0 1 auto;
            }
            @include sp{
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              flex: 1 1 100%;
            }
            input, select{
              @include pc{

              }
              @include sp{
                flex: 1 1 100%;
              }
            }
            &.r-postal {
              input {
                @include pc{
                  width: 210px;
                  margin-left: 24px;
                }
                @include sp{
                  width: 50%;
                }
              }
            }
            &.r-pref {
              .c-select{
                @include pc{
                  width: 210px;
                  margin-left: 104px;
                }
                @include sp{
                }
                select {
                  @include pc{
                    width: 211px;
                  }
                  @include sp{
                  }
                }

              }
            }
            &.r-cities {
              input {
                @include pc{
                  width: 210px;
                  margin-left: 120px;
                }
                @include sp{
                  width: 100%;
                }
              }
            }
            &.r-housenumber {
              input {
                @include pc{
                  width: 210px;
                  margin-left: 136px;
                }
                @include sp{
                  width: 100%;
                }
              }
            }
            &.r-building {
              input {
                @include pc{
                  width: 210px;
                  margin-left: 41px;
                }
                @include sp{
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }

    // フォーム：テキスト
    &.r-text {
      .p-input_item_form {
        &_container {
          gap: 20px;
          flex-wrap: wrap;
          input {
            width: 100%;
            @include pc{
              flex: 0 1 calc(50% - 10px);
            }
            @include sp{
              flex: 0 1 100%;
            }
          }
        }
      }
    }

    // フォーム：セレクト
    &.r-select {
      .p-input_item_form {
        &_container {
          flex-wrap: wrap;
          @include pc{
            gap: 10px;
          }
          @include sp{
            gap: 8px 4%;
          }
        }
      }
    }

    // フォーム：ラジオボタン
    &.r-radio {
      .p-input_item_form {
        &_container {
          gap: 10px 20px;
          flex-wrap: wrap;
          & > label {
            text-indent: -21px;
            padding-left: 21px;
            @include pc{
              flex: 0 1 auto;
            }
            @include sp{
              flex: 0 1 auto;
            }
            input {
              vertical-align: top;
              margin-top: 6px;
              margin-right: 8px;
            }
          }
        }
      }
    }

    // フォーム：チェックボックス
    &.r-checkbox {
      .p-input_item_form {
        &_container {
          gap: 10px 20px;
          flex-wrap: wrap;
          & > label {
            display: inline-flex;
            align-items: center;
            @include pc{
              flex: 0 1 auto;
            }
            @include sp{
              flex: 0 1 auto;
            }
            input {
              margin: 0;
              margin-right: 5px;
            }
          }
        }
      }
    }

    // フォーム：添付ファイル
    &.r-fileupload {
      .p-input_item_form {
        &_container {
          gap: 10px 10px;
          flex-wrap: wrap;
          .fileupload_container {
            padding: 10px 20px;
            border-radius: 10px;
            cursor: pointer;
            border: 1px solid;
          }
          label {
            padding: 10px 40px;
            border-radius: 10px;
            cursor: pointer;
            border: 1px solid;
            &:hover {
              opacity: .8;
              transition: opacity .2s;
            }
            [data-view-mode="light"] & {
              background-color: #00489E;
              color: $button-color2;
              border-color: $button-color1;
            }
            [data-view-mode="dark"] & {
              background-color: #fff;
              color: #202124;
              border-color: #fff;
            }
          }
          input[type="file"] {
            display: none;
          }
          .display_filename {
            display: flex;
            align-items: center;
            border: 1px solid;
            border-radius: 10px;
            padding: 10px 1em;
            min-height: 46px;
            @include pc{
              width: 320px;
            }
            @include sp{
              width: 100%;
            }
            [data-view-mode="light"] & {
              border-color: $button-color1;
            }
            [data-view-mode="dark"] & {
              border-color: #fff;
            }
          }
        }
      }
    }

    // フォーム：同意
    &.r-agree {
      // border-top: 1px solid #ddd;
      // padding-top: 40px;
      // text-align: center;
      .p-input_item_form {
        // text-align: center;
        &_container {
          // justify-content: center;
          gap: 10px 20px;
          flex-wrap: wrap;
          & > label {
            display: inline-flex;
            align-items: center;
            @include pc{
              flex: 0 1 auto;
            }
            @include sp{
              flex: 0 1 auto;
            }
            input {
              margin: 0;
              margin-right: 5px;
            }
          }
        }
      }
    }

    .error {
      border-color: red !important;
    }
  }
  &_confirm {
    flex: 1 0 calc(50% - 5px);
    font-weight: 100;
    border: 1px solid;
    border-color: #ccc;
    border-radius: 6px;
    padding: 10px;
    span {
      display: block;
      font-size: 12px;
      font-weight: normal;

    }
  }
}