.l-header{
  // background-color: $bgColor-mode-light;
  // height: 57px;
  color: #fff;
  @include pc{
    width: 100%;
    margin: 0 auto;
  }
  @include sp{
    width: 100%;
  }
}