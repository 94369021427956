.p-pageTitle{
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  @include pc{
    font-size: 2.1rem;
  }
  @include sp{
    font-size: 2.1rem;
  }  
}